/* eslint-disable consistent-return */
import swal from 'sweetalert';
import PromptQueue from '../../../../../lib/prompt-queue';

(() => {
  angular
    .module('app')
    .controller('clinic.views.patient.ticket.index', PatientTicketPageController);

  PatientTicketPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    '$filter',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    'lookupModal',
    'abp.services.app.clinicEmployee',
    'moment',
  ];

  function PatientTicketPageController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    $filter,
    patientTicketSvc,
    commonLookupSvc,
    lookupModal,
    clinicEmployeeSvc,
    moment
  ) {
    const vm = this;
    let triggerDraftConfirmation = false;
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.supportEmail = abp.setting.get('Hms.General.ClinicSupportEmail');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.loading = 0;
    vm.savingDraft = 0;
    vm.isConfirm = 0;
    vm.saving = 0;
    vm.isUpdated = false;

    vm.employees = null;

    vm.ticketDate = moment();
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.employeeId = $stateParams.employeeId;
    vm.isCreate = vm.employeeId > 0;
    vm.patientSessionId = $stateParams.patientSessionId;
    vm.isLateSubmission = $stateParams.patientSessionId > 0;
    vm.lateSubmissionReason = $stateParams.lateSubmissionReason;
    vm.lateSubmissionRequestorName = $stateParams.lateSubmissionRequestorName;
    vm.doctors = [];
    vm.doctor = null;
    vm.treatments = [];
    vm.quantity = [];
    vm.strength = [];
    vm.dosage = [];
    vm.patient = null;
    vm.clinic = {};
    vm.clinicLocations = [];
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.isMcStartDateAlsoCheckedInDate = false;
    vm.hasMultipleCheckInWithSharedPool = false;

    vm.canSubmit = true;

    vm.hasSearchEmployeesForPanelEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SearchEmployeesForPanelEnhancement'
    );

    vm.permissions = {
      createDoctor: abp.auth.isGranted('Clinic.Doctors.Create'),
    };

    vm.componentStates = {
      consultation: {
        isDisabled: false,
        isExpanded: true,
        isCompleted: false,
        isDirty: false,
      },
      medicalCertificate: {
        isDisabled: true,
        isExpanded: false,
        isCompleted: false,
        isDirty: false,
      },
      treatment: {
        isDisabled: true,
        isExpanded: false,
        isCompleted: false,
        isDirty: false,
      },
      pharmacy: {
        isDisabled: true,
        isExpanded: false,
        isCompleted: false,
        isDirty: false,
      },
    };

    // Used for isDirty validation event handler registration with child components.

    vm.onConsultationDirtyEvent = {
      handler: null,
    };
    vm.onMedicalCertificateDirtyEvent = {
      handler: null,
    };
    vm.onTreatmentDirtyEvent = {
      handler: null,
    };
    vm.onPharmacyDirtyEvent = {
      handler: null,
    };

    // Used for setPristine event handler registration with child components.

    vm.onConsultationSetPristineEvent = {
      handler: null,
    };
    vm.onMedicalCertificateSetPristineEvent = {
      handler: null,
    };
    vm.onTreatmentSetPristineEvent = {
      handler: null,
    };
    vm.onPharmacySetPristineEvent = {
      handler: null,
    };

    vm.$doCheck = $doCheck;

    // Control data entry flow.
    vm.completeConsultationEntry = completeConsultationEntry;
    vm.completeMedicalCertification = completeMedicalCertification;
    vm.completeTreatmentEntry = completeTreatmentEntry;
    vm.completePharmacyPrescriptionEntry = completePharmacyPrescriptionEntry;

    // Sync with component inner isExpanded state.
    vm.syncConsultationExpansion = syncConsultationExpansion;

    vm.syncMedicalCertificateExpansion = syncMedicalCertificateExpansion;
    vm.syncTreatmentExpansion = syncTreatmentExpansion;
    vm.syncPharmacyExpansion = syncPharmacyExpansion;

    // Validate components had captured all required data.
    vm.isCompleted = isCompleted;
    // Validate components form is dirty.
    vm.isDirty = isDirty;

    vm.updatedTicketDate = updatedTicketDate;
    vm.setPristine = setPristine;

    // Restore data entry state from draft.
    vm.restoreDataEntryState = restoreDataEntryState;
    vm.returnToCheckIn = returnToCheckIn;

    // Change location modal.
    vm.changeLocation = changeLocation;
    // Create doctor modal.
    vm.createDoctor = createDoctor;
    vm.refreshDoctors = refreshDoctors;
    // Treatment management.
    vm.treatmentHasValue = treatmentHasValue;
    vm.isTreatmentValid = isTreatmentValid;

    // Calculate amount.
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTaxAmount = getTaxAmount;
    vm.getTotalAmount = getTotalAmount;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getCoPayAmount = getCoPayAmount;
    vm.getCoPayValueDisplay = getCoPayValueDisplay;
    vm.getCoveredTaxAmount = getCoveredTaxAmount;
    vm.getUncoveredTaxAmount = getUncoveredTaxAmount;
    vm.createOrEditTicket = createOrEditTicket;
    vm.saveDraft = saveDraft;
    vm.validateLateSubmission = validateLateSubmission;
    vm.updatePlaceholder = updatePlaceholder;
    vm.previewTicket = previewTicket;
    vm.confirm = confirm;

    // Get employee for employee card.
    vm.getEmployees = getEmployees;
    vm.checkMultipleCheckInWithSharedPool = checkMultipleCheckInWithSharedPool;
    init();

    function init() {
      vm.loading += 1;
      patientTicketSvc
        .getPanelTicketForEdit({
          employeeId: $stateParams.employeeId,
          ticketNumber: $stateParams.ticketNumber,
          clinicLocationId: $stateParams.clinicLocationId,
          serviceType: $stateParams.serviceType,
          ticketType: $stateParams.ticketType,
          patientSessionId: $stateParams.patientSessionId,
        })
        .success((data) => {
          vm.isLoading = true;
          vm.doctors = data.doctors || [];
          vm.patient = data.patient;
          vm.clinic = data.clinic || {};
          vm.treatments = data.treatments || [];
          vm.ticketNumber = data.ticket.ticketNumber;
          vm.employeeId = data.patient.id;
          vm.ticketDate = moment(data.ticket.ticketDate);
          vm.ticket = data.ticket;
          vm.icpcs = data.icpcs;
          vm.quantity = data.quantityUnits;
          vm.strength = data.strengthUnits;
          vm.dosage = data.dosageUnits;
          vm.consultationAveragePrice = data.clinic ? data.clinic.consultationAveragePrice : null;
          vm.currencyCode = data.currencyCode;
          vm.countryCode = data.countryCode;

          // Get tax details.

          vm.tax = data.taxDetails;
          vm.hasTax = vm.tax && vm.tax.taxPercentage != null && vm.tax.taxPercentage > 0;

          // Late submission doesn't support pharmacy module currently.

          if (!vm.isLateSubmission) {
            vm.pharmacy = data.pharmacy;
            vm.pharmacyLocations = data.pharmacyLocations;
            vm.pharmacyTreatments = data.pharmacyTreatments;
          }

          // Prefill with draft if any.

          if (data.ticketDraft) {
            const draft = JSON.parse(data.ticketDraft);
            vm.ticket = draft;
            vm.restoreDataEntryState();
          }

          if (vm.isLateSubmission) {
            vm.validateLateSubmission(data.patientSessionId);
          }

          // Get the prescription.

          if (data.pharmacy) {
            if (!vm.ticket.pharmacyPrescription) {
              vm.ticket.pharmacyPrescription = {
                issue: false,
                customerEmail: data.patient.emailAddress,
                customerPhone: data.patient.mobileNumber,
                treatments: [],
              };
            }

            if (!Object.prototype.hasOwnProperty.call(vm.ticket.pharmacyPrescription, 'issue')) {
              vm.ticket.pharmacyPrescription.issue =
                vm.ticket.pharmacyPrescription.treatments &&
                vm.ticket.pharmacyPrescription.treatments.length;
            }
          }

          // Process remark tags.

          _.each(vm.ticket.treatments, (t) => {
            t.treatment = _.find(vm.treatments, ['id', t.treatmentId]);
          });

          // Map ticket.treatments.patientTicketPrescriptions to prescriptions.

          if (vm.ticket.treatments) {
            _.each(vm.ticket.treatments, (treatment) => {
              if (treatment.patientTicketPrescriptions) {
                $.extend(treatment, {
                  prescriptions: treatment.patientTicketPrescriptions,
                });
                delete treatment.patientTicketPrescriptions;
              }
            });
          }

          refreshTicketTreatments();

          vm.attachments = vm.ticket.attachments;
          vm.newAttachments = [];

          if (vm.ticket.medicalCertificate) {
            vm.ticket.medicalCertificate.startDate = moment(vm.ticket.medicalCertificate.startDate);
            vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.endDate);
            vm.ticket.checkedInTime = moment(vm.ticket.checkedInTime);
          }

          const strAmt = $filter('currencyFormat')(vm.ticket.coveredAmount, vm.currencyCode);
          vm.reviseCoveredAmountUpwardErrorMessage = App.localize(
            'ReviseCoveredAmountUpwardErrorMessage',
            strAmt
          );

          if (!vm.clinic.clinicLocationId && vm.isCreate) {
            vm.changeLocation();
          }

          if (vm.isClinic) {
            vm.getEmployees();

            if (vm.isCreate) {
              vm.checkMultipleCheckInWithSharedPool();
              vm.multipleCheckInWarningMessage = App.localize(
                'MultipleCheckInWithSharedPoolWarningMessage',
                vm.supportEmail
              );
            }
          }
        })
        .finally(() => {
          updateStepIndicator(0); // Update step indicator
          vm.loading -= 1;
        });
    }
    function $doCheck() {
      if (!triggerDraftConfirmation && vm.isDirty()) {
        triggerDraftConfirmation = true; // Set true once components form is dirty
      }

      if (vm.isConfirm && vm.isDirty()) {
        _.forIn(vm.componentStates, (d) => {
          if (d.isDirty) {
            d.isCompleted = false;
          }
        });
        vm.isConfirm = false; // Reset vm.isConfirm once any comfirmed components form is dirty
        updateStepIndicator(0);
      }
    }
    function completeConsultationEntry() {
      vm.componentStates.consultation.isExpanded = false;
      vm.componentStates.consultation.isCompleted = true;

      vm.componentStates.medicalCertificate.isDisabled = false;
      vm.componentStates.medicalCertificate.isExpanded = true;
    }

    function completeMedicalCertification() {
      vm.componentStates.medicalCertificate.isExpanded = false;
      vm.componentStates.medicalCertificate.isCompleted = true;

      vm.componentStates.treatment.isDisabled = false;
      vm.componentStates.treatment.isExpanded = true;
    }

    function completeTreatmentEntry() {
      vm.componentStates.treatment.isExpanded = false;
      vm.componentStates.treatment.isCompleted = true;

      _.each(vm.ticket.treatments, (t) => {
        t.treatment = _.find(vm.treatments, ['id', t.treatmentId]);
      });

      if (vm.pharmacy) {
        vm.componentStates.pharmacy.isDisabled = false;
        vm.componentStates.pharmacy.isExpanded = true;
      } else {
        vm.componentStates.pharmacy.isCompleted = true;
      }
    }

    function completePharmacyPrescriptionEntry() {
      vm.componentStates.pharmacy.isExpanded = false;
      vm.componentStates.pharmacy.isCompleted = true;

      if (vm.ticket.pharmacyPrescription) {
        _.each(vm.ticket.pharmacyPrescription.treatments, (t) => {
          t.treatment = _.find(vm.treatments, ['id', t.treatmentId]);
        });
      }
    }

    function syncConsultationExpansion(isExpanded) {
      vm.componentStates.consultation.isExpanded = isExpanded;
    }

    function syncMedicalCertificateExpansion(isExpanded) {
      vm.componentStates.medicalCertificate.isExpanded = isExpanded;
    }

    function syncTreatmentExpansion(isExpanded) {
      vm.componentStates.treatment.isExpanded = isExpanded;
    }

    function syncPharmacyExpansion(isExpanded) {
      vm.componentStates.pharmacy.isExpanded = isExpanded;
    }

    function isCompleted() {
      return (
        vm.componentStates.consultation.isCompleted &&
        vm.componentStates.medicalCertificate.isCompleted &&
        vm.componentStates.treatment.isCompleted &&
        vm.componentStates.pharmacy.isCompleted
      );
    }

    function isDirty() {
      setDirty();
      return (
        vm.componentStates.consultation.isDirty ||
        vm.componentStates.medicalCertificate.isDirty ||
        vm.componentStates.treatment.isDirty ||
        vm.componentStates.pharmacy.isDirty
      );
    }

    function updatedTicketDate() {
      if (vm.isCreate && vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.ticketDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.ticketDate).startOf('day');
      }
    }
    function setPristine() {
      if (vm.onConsultationSetPristineEvent.handler) {
        vm.onConsultationSetPristineEvent.handler();
      }

      if (vm.onMedicalCertificateSetPristineEvent.handler) {
        vm.onMedicalCertificateSetPristineEvent.handler();
      }

      if (vm.onTreatmentSetPristineEvent.handler) {
        vm.onTreatmentSetPristineEvent.handler();
      }

      if (vm.onPharmacySetPristineEvent.handler) {
        vm.onPharmacySetPristineEvent.handler();
      }
    }

    function restoreDataEntryState() {
      if (
        !vm.ticket.doctorId ||
        vm.ticket.hasConsultation === null ||
        (vm.ticket.hasConsultation && !vm.ticket.consultation)
      ) {
        return;
      }

      if (vm.ticket.consultation) {
        if (
          !(
            typeof vm.ticket.consultation.amount !== 'undefined' &&
            vm.ticket.consultation.symptoms &&
            !_.isEmpty(vm.ticket.consultation.symptoms)
          )
        ) {
          return;
        }

        if (vm.ticket.referralLetter) {
          if (
            !(
              typeof vm.ticket.referralLetter.amount !== 'undefined' &&
              vm.ticket.referralLetter.notes &&
              vm.ticket.referralLetter.physicalSigns &&
              vm.ticket.referralLetter.provisionalDiagnosis &&
              vm.ticket.referralLetter.referral
            )
          ) {
            return;
          }
        }
      }

      vm.completeConsultationEntry();

      if (vm.ticket.hasMc === null) {
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);

        const mcStartDayOffset = startDate.diff(
          moment(vm.ticket.checkedInTime).startOf('day'),
          'days'
        );
        if (
          endDate < startDate ||
          mcStartDayOffset < 0 ||
          mcStartDayOffset > 1 ||
          _.isEmpty(vm.ticket.medicalCertificate.remark)
        ) {
          return;
        }
      }

      vm.completeMedicalCertification();

      if (vm.ticket.pharmacyPrescription && vm.ticket.pharmacyPrescription.issue) {
        vm.completeTreatmentEntry();
      }
    }

    function returnToCheckIn() {
      // Check whether component's form is dirty.

      if (triggerDraftConfirmation) {
        $uibModal.open({
          templateUrl: require('../draftConfirmationModal.html'),
          controller: 'clinic.views.patient.draftConfirmationModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            ticket: vm.ticket,
            clinic: vm.clinic,
            employeeId: vm.employeeId,
          },
        });
      } else {
        $state.go('clinic.checkin');
      }
    }

    function changeLocation() {
      lookupModal.open({
        title: App.localize('SelectPanelLocation'),
        serviceMethod: commonLookupSvc.findClinicLocations,
        callback(location) {
          vm.clinic.clinicLocationId = parseInt(location.value, 10);
          vm.clinic.name = location.name;
          vm.clinic.address = location.address;
          vm.clinic.contactNumber = location.contactNumber;
          vm.clinic.midnightSurcharge = location.midnightSurcharge;
          vm.ticket.doctorId = null;
          vm.consultationAveragePrice = location.consultationAveragePrice;
          vm.refreshDoctors();
        },
      });
    }

    function createDoctor() {
      if (vm.isCreate && vm.permissions.createDoctor) {
        $uibModal
          .open({
            templateUrl: require('../../doctors/createOrEditModal.html'),
            controller: 'clinic.views.doctors.createOrEditModal as vm',
            backdrop: 'static',
            resolve: {
              doctorId: null,
              clinicLocationId: vm.clinic.clinicLocationId,
            },
          })
          .result.then((result) => {
            vm.refreshDoctors(result.doctorId);
          });
      }
    }

    function refreshDoctors(doctorId) {
      if (vm.isCreate) {
        patientTicketSvc
          .getLocationDoctors({
            id: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            vm.doctors = data.items;
          })
          .finally(() => {
            if (!vm.doctors.length) {
              vm.createDoctor();
            }
            vm.ticket.doctorId = doctorId.toString();
          });
      }
    }

    function treatmentHasValue(treatment) {
      return treatment.treatmentId || treatment.amount;
    }

    function refreshTicketTreatments() {
      let hasBlank = _.some(vm.ticket.treatments, (t) => !vm.treatmentHasValue(t));

      while (!hasBlank || vm.ticket.treatments.length < 1) {
        vm.ticket.treatments.push({});
        vm.ticket.treatments[vm.ticket.treatments.length - 1].prescriptions = [{}];
        hasBlank = true;
      }
    }

    function isTreatmentValid() {
      let isValid = false;
      _.forEach(vm.ticket.treatments, (t) => {
        if (t.treatmentId) {
          isValid = false; // Reset value for each treatment
          _.forEach(t.prescriptions, (p) => {
            if (p.drugId) {
              isValid = p.quantity && p.amount && p.amount >= 0 && Number.isFinite(p.amount);
              if (!isValid) {
                return false; // Break loop once invalid
              }
            }
          });
          if (!isValid) return false; // Break loop once invalid
        }
      });
      return isValid;
    }

    function getAllowanceDisplay() {
      return Math.min(vm.patient?.allowance, vm.defaultMaxAllowance);
    }

    function getSubtotalAmount() {
      let amount = 0;
      if (vm.ticket && vm.ticket.treatments) {
        _.each(vm.ticket.treatments, (t) => {
          _.each(t.prescriptions, (p) => {
            if (t.treatmentId && p.amount) {
              amount += p.amount;
            }
          });
        });

        if (vm.ticket.consultation) amount += vm.ticket.consultation.amount;

        if (vm.ticket.referralLetter) amount += vm.ticket.referralLetter.amount;
      }
      if (vm.hasTax && vm.tax.isTaxInclusive) return amount / (1 + vm.tax.taxPercentage / 100);
      return amount;
    }

    function getTaxAmount() {
      if (vm.ticket && vm.ticket.treatments && !vm.tax.isTaxInclusive)
        return vm.getSubtotalAmount() * (vm.tax.taxPercentage / 100);
      if (vm.ticket && vm.ticket.treatments && vm.tax.isTaxInclusive)
        return vm.getSubtotalAmount() * (1 + vm.tax.taxPercentage / 100) - vm.getSubtotalAmount();
      return 0;
    }

    function getTotalAmount() {
      if (vm.ticket && vm.ticket.treatments)
        return vm.hasTax ? vm.getSubtotalAmount() + vm.getTaxAmount() : vm.getSubtotalAmount();
      return 0;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let total = 0;
      let uncovered = 0;
      let coPay = 0;
      let exceeded = 0;
      let collectCash = 0;
      let copayValueDisplay = 'NotApplicable';
      let coveredTax = 0;
      let uncoveredTax = 0;
      vm.remindUserUpdateCollectCashAmountMessage = null;

      if (vm.ticket) {
        _.each(vm.ticket.treatments, (t) => {
          if (t && t.prescriptions) {
            _.each(t.prescriptions, (p) => {
              const rate = p.amount;
              if (t.treatment && rate) {
                if (!t.treatment.notCovered) {
                  claimable += rate;
                } else {
                  uncovered += rate;
                }

                total += rate;
              }
            });
          }
        });

        if (vm.ticket.consultation) {
          const consultationAmount = vm.ticket.consultation.amount;
          const consultationTreatment =
            vm.countryCode === 'ID'
              ? _.find(
                  vm.treatments,
                  (q) => q.displayName === 'Biaya konsultasi dan visite dokter umum'
                )
              : _.find(vm.treatments, (q) => q.displayName === 'Consultation');
          if (!consultationTreatment.notCovered) {
            claimable += consultationAmount;
          } else {
            uncovered += consultationAmount;
          }

          total += consultationAmount;

          if (vm.ticket.referralLetter) {
            if (!consultationTreatment.notCovered) {
              claimable += vm.ticket.referralLetter.amount;
            } else {
              uncovered += vm.ticket.referralLetter.amount;
            }

            total += vm.ticket.referralLetter.amount;
          }
        }
      }

      // Add tax to total and claimable amount.

      if (vm.hasTax && vm.tax.taxPercentage && !vm.tax.isTaxInclusive) {
        total += App.roundAmount(total * (vm.tax.taxPercentage / 100));
        claimable += App.roundAmount(claimable * (vm.tax.taxPercentage / 100));
        uncovered += App.roundAmount(uncovered * (vm.tax.taxPercentage / 100));
      }
      if (vm.hasTax && vm.tax.taxPercentage) {
        coveredTax = (claimable / (100 + vm.tax.taxPercentage)) * vm.tax.taxPercentage;
        uncoveredTax = (uncovered / (100 + vm.tax.taxPercentage)) * vm.tax.taxPercentage;
      }

      // NOTE: Co-payment is now based on covered amount.
      // More info: HM-4333.
      // Calculation for co-payment: covered amount * co-pay percentage.

      if (vm.patient && vm.ticket) {
        // Apply co-pay for normal/reimbursement tickets.

        if (claimable) {
          if (vm.patient.coPay) {
            coPay = vm.patient.coPay.value;
            copayValueDisplay = vm.patient.coPay.isPercentage
              ? `${vm.patient.coPay.value}%`
              : `${$filter('currencyFormat')(vm.patient.coPay.value, vm.currencyCode)}`;

            // Calculate new co-pay amount.

            if (vm.patient.coPay.isPercentage) {
              coPay = App.roundAmount((coPay * claimable) / 100);
            } else {
              coPay = Math.min(claimable, coPay);
            }
          }
        }
        if (vm.patient.allowance > 0) {
          exceeded = Math.max(claimable - coPay - vm.patient.allowance, 0);
        } else {
          exceeded = Math.max(claimable - coPay, 0);
        }

        if (vm.isCreate) {
          collectCash = coPay + uncovered + exceeded;
        } else {
          collectCash = vm.ticket.manualPayment;
        }
      }

      // Round number to 2 decimal places.

      total = App.roundAmount(total);
      collectCash = App.roundAmount(collectCash);
      coPay = App.roundAmount(coPay);
      exceeded = App.roundAmount(exceeded);

      // Check collect cash against employee limit and alert host only when user edit amount

      const patientAllowance = vm.getAllowanceDisplay();
      if (
        vm.ticket?.amount !== total &&
        claimable > vm.ticket?.coveredAmount &&
        patientAllowance < claimable
      ) {
        vm.remindUserUpdateCollectCashAmountMessage = App.localize(
          'RemindUserUpdateCollectCashAmountMessage',
          $filter('currencyFormat')(patientAllowance, vm.currencyCode)
        );
      }

      return {
        total,
        claimable: App.roundAmount(total - collectCash),
        manualPayment: collectCash,
        uncovered,
        exceeded,
        copay: coPay,
        copayValueDisplay,
        coveredTax,
        uncoveredTax,
      };
    }

    function getManualPaymentAmount() {
      return vm.calculateAmountCoverage().manualPayment;
    }

    function getCoveredAmount() {
      return vm.calculateAmountCoverage().claimable;
    }

    function getUncoveredAmount() {
      return vm.calculateAmountCoverage().uncovered;
    }

    function getExceededAmount() {
      return vm.calculateAmountCoverage().exceeded;
    }

    function getCoPayAmount() {
      return vm.calculateAmountCoverage().copay;
    }

    function getCoPayValueDisplay() {
      return vm.calculateAmountCoverage().copayValueDisplay;
    }

    function getCoveredTaxAmount() {
      return vm.calculateAmountCoverage().coveredTax;
    }

    function getUncoveredTaxAmount() {
      return vm.calculateAmountCoverage().uncoveredTax;
    }

    function createOrEditTicket(isPreview) {
      if (vm.saving !== 0) return;
      vm.saving += 1;

      const prompts = new PromptQueue();

      if (!vm.clinic.clinicLocationId) {
        vm.saving -= 1;
        abp.message.error(App.localize('InvalidPanelLocation'));
        return;
      }

      if (!vm.ticket.doctorId) {
        vm.saving -= 1;
        abp.message.error(App.localize('DoctorNotSelected'));
        return;
      }

      /* Validates properties */

      // Treatment Validation

      if (vm.ticket.treatments) {
        let treatmentIsInvalid = false;
        _.each(vm.ticket.treatments, (t) => {
          if (t.treatmentId) {
            _.each(t.prescriptions, (p) => {
              if ((p.drugId && !p.amount) || p.amount < 0) {
                treatmentIsInvalid = true;
              }
              return treatmentIsInvalid;
            });
          }
        });

        if (treatmentIsInvalid) {
          vm.saving -= 1;
          abp.message.error(App.localize('InvalidTreatmentRateAmount'));
          return;
        }
      }

      // End of Treatment Validation

      // Medical Certificate Validation

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        if (endDate < startDate) {
          vm.saving -= 1;
          abp.message.error(App.localize('McEndDateEarlierThanStartDate'));
          return;
        }
        vm.isMcStartDateAlsoCheckedInDate =
          vm.ticket.medicalCertificate.startDate.diff(
            moment(vm.ticket.checkedInTime).startOf('days'),
            'days'
          ) === 0;
        vm.ticket.medicalCertificate.effectiveMcDays = endDate.diff(startDate, 'days') + 1;

        const mcStartDayOffset = startDate.diff(
          moment(vm.ticket.checkedInTime).startOf('day'),
          'days'
        );
        if (mcStartDayOffset < 0) {
          vm.saving -= 1;
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        if (mcStartDayOffset > 1) {
          if (vm.ticket.ticketType === 2) {
            prompts.add(
              swal,
              {
                title: App.localize('AreYouSure'),
                text: App.localize('McStartDateTooFarFromTicketDateWarning'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true,
              },
              (isConfirm) => {
                vm.canSubmit = isConfirm;
              }
            );
          } else {
            vm.saving -= 1;
            abp.message.error(App.localize('McStartDateTooFarFromTicketDate'));
            return;
          }
        }
      }

      // End of Medical Certificate Validation

      // Filter away attachments marked for deletion.

      const attachments = [];
      _.each(vm.attachments, (attachment) => {
        if (!attachment.isDelete) attachments.push(attachment.id);
      });

      // Construct input request.

      if (vm.isLateSubmission) {
        const draft = angular.toJson(vm.ticket);
        vm.ticket.latePatientTicketSubmission = {
          patientSessionId: vm.patientSessionId,
          reason: vm.lateSubmissionReason,
          requestorName: vm.lateSubmissionRequestorName,
          patientTicketDraftJson: draft,
        };
      }

      if (vm.ticket.consultation) {
        if (typeof vm.ticket.consultation.symptoms[0] === 'object') {
          const formattedSymptoms = vm.ticket.consultation
            ? vm.ticket.consultation.symptoms.map((symptom) => symptom.codeDesc)
            : null;
          vm.ticket.consultation.symptoms = formattedSymptoms;
        }
      }

      const input = {
        doctorId: vm.ticket.doctorId,
        treatments: _.chain(vm.ticket.treatments)
          .filter('treatmentId')
          .map((t) => ({
            treatmentId: t.treatmentId,
            prescriptions: _.chain(t.prescriptions)
              .filter('drugId')
              .map((p) =>
                p.drugId > 0
                  ? p
                  : _.extend({}, p, {
                      drugId: null,
                    })
              )
              .value(),
          }))
          .value(),
        manualPayment: vm.getManualPaymentAmount(),
        clinicLocationId: vm.clinic.clinicLocationId,
        ticketDate: vm.ticketDate,
        ticketType: vm.ticket.ticketType,
        attachments,
        newAttachments: vm.newAttachments,
        medicalCertificate: vm.ticket.medicalCertificate ? vm.ticket.medicalCertificate : null,
        // consultation: formattedConsultations,
        consultation: vm.ticket.consultation ? vm.ticket.consultation : null,
        pharmacyPrescription: null,
        referralLetter: vm.ticket.referralLetter ? vm.ticket.referralLetter : null,
        latePatientTicketSubmission: vm.ticket.latePatientTicketSubmission
          ? vm.ticket.latePatientTicketSubmission
          : null,
        preTaxAmount: vm.hasTax ? vm.getSubtotalAmount() : vm.getTotalAmount(),
        requestId: vm.ticket.requestId,
      };

      if (vm.isCreate && vm.pharmacy && vm.ticket.pharmacyPrescription.issue) {
        input.pharmacyPrescription = _.extend({}, vm.ticket.pharmacyPrescription);
        input.pharmacyPrescription.treatments = _.chain(input.pharmacyPrescription.treatments)
          .filter('treatmentId')
          .map((t) => ({
            treatmentId: t.treatmentId,
            prescriptions: _.chain(t.prescriptions)
              .filter('drugId')
              .map((p) =>
                p.drugId > 0
                  ? p
                  : _.extend({}, p, {
                      drugId: null,
                    })
              )
              .value(),
          }))
          .value();
      }

      if (vm.isCreate) {
        if (vm.employee.previousCheckins.totalCount === 0) {
          initiateCheckIn(isPreview, input);
          return;
        }

        // Check if there's any checkins within 48 hours.

        const latestCheckIn = _.maxBy(vm.employee.previousCheckins.items, 'checkinTime');

        let latestCheckInTime = latestCheckIn.checkinTime;

        latestCheckInTime = latestCheckInTime.replace('T', ' ');
        latestCheckInTime = new Date(latestCheckInTime);
        const timeNow = moment();

        const ms = moment(timeNow, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(latestCheckInTime, 'DD/MM/YYYY HH:mm:ss')
        );
        const d = moment.duration(ms);
        const s = d.asHours();

        if (s > 47) {
          initiateCheckIn(isPreview, input);
          return;
        }

        swal(
          {
            title: App.localize('DuplicateClaimWarning'),
            text: App.localize(
              'DuplicateClaimWarningText',
              latestCheckIn.ticketNumber,
              App.getDateTimeString(latestCheckIn.ticketDate)
            ),
            type: 'warning',
            customClass: 'custom-duplicate-alert',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: App.localize('CancelCheckInWarning'),
            confirmButtonText: App.localize('Yes'),
            confirmButtonColor: '#C1C1C1',
            html: true,
          },
          // eslint-disable-next-line prefer-arrow-callback, func-names
          function (isConfirm) {
            if (isConfirm) {
              initiateCheckIn(isPreview, input);
              return;
            }

            checkOut();
          }
        );
      } else {
        prompts.add(
          swal,
          {
            title: App.localize('EditPatientTicket'),
            text: App.localize('WhyEditPatientTicket'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: true,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (remarks) => {
            if (remarks === false) {
              vm.saving -= 1;
              return false;
            }
            if ($.trim(remarks || '') === '') {
              swal.showInputError(App.localize('PleaseExplain'));
              vm.saving -= 1;
              return false;
            }

            editTicket(input, $.trim(remarks));
            return true;
          }
        );

        prompts.run();
      }
    }

    function initiateCheckIn(isPreview, input) {
      const prompts = new PromptQueue();
      if (!vm.ticket.medicalCertificate) {
        const warning = vm.isClinic
          ? App.localize('PanelNoMcReminderWarning')
          : App.localize('NoMcReminderWarning');
        prompts.add(abp.message.confirm, warning, App.localize('AreYouSure'), (d) => {
          vm.canSubmit = d;
        });
      } else if (!vm.isMcStartDateAlsoCheckedInDate) {
        prompts.add(
          abp.message.confirm,
          App.localize('MCStartDateDifferentFromCheckInDate'),
          App.localize('AreYouSure'),
          (d) => {
            vm.canSubmit = d;
          }
        );
      }

      if (vm.pharmacy && !vm.getSubtotalAmount()) {
        prompts.add(
          abp.message.confirm,
          App.localize('NoAmountChargedWarningMessage'),
          App.localize('AreYouSure'),
          (d) => {
            vm.canSubmit = d;
          }
        );
      }

      prompts.run(() => {
        if (!vm.canSubmit) {
          vm.saving -= 1;
          return;
        }
        if (isPreview) {
          vm.previewTicket();
          return;
        }
        createTicket(input);
      });
    }

    // #region Utilities

    function createTicket(input) {
      patientTicketSvc
        .createTicketForPanel(
          $.extend(input, {
            employeeId: vm.employeeId,
          })
        )
        .success((data) => {
          vm.isUpdated = true;

          function promptWarning(title, text) {
            let warningTitle = title;
            warningTitle = _.isString(title) ? title : '';
            let warningText = text;
            warningText = _.isString(text) ? text : '';

            window.onbeforeunload = () => title;

            swal(
              {
                warningTitle,
                type: 'warning',
                warningText,
                showConfirmButton: true,
              },
              () => {
                // unregister listener.

                window.onbeforeunload = null;
              }
            );
            App.swal.disableButtons(5);
          }
          if (vm.ticket.ticketType === 0 && vm.getManualPaymentAmount()) {
            promptWarning(
              App.localize(
                'RememberCollectXPaymentFromPatient',
                vm.currencyCode + vm.getManualPaymentAmount()
              )
            );
          }
          if (data.latePatientTicketSubmissionId) {
            promptLateSubmissionSuccess();
          } else {
            abp.notify.info(App.localize('TicketXIssued', data.ticketNumber));
            $state.go('clinic.patientTicketSubmited', {
              ticketNumber: data.ticketNumber,
            });
          }
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function promptLateSubmissionSuccess() {
      swal(
        {
          title: App.localize('LateSubmissionRequestSent'),
          type: 'success',
          text: `${App.localize('LateSubmissionSuccessNarrative1')}<br />${App.localize(
            'LateSubmissionSuccessNarrative2'
          )}`,
          showConfirmButton: true,
          confirmButtonText: App.localize('Understood'),
          html: true,
        },
        () => {
          window.onbeforeunload = null; // unregister
        }
      );
      $state.go('clinic.panelDashboard');
    }

    function editTicket(input, remarks) {
      patientTicketSvc
        .editTicketForPanel(
          $.extend(input, {
            ticketNumber: vm.ticketNumber,
            modificationRemarks: remarks,
          })
        )
        .success(() => {
          vm.isUpdated = true;
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.go('patientTickets');
          swal.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    // #endregion Utilities

    function checkOut() {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkOut({
          employeeId: vm.employee.id,
          clinicLocationId: vm.clinic.clinicLocationId,
          cancellationRemarks: 'DoubleCheckIn',
        })
        .success(() => {
          swal.close();
        })
        .finally(() => {
          vm.loading -= 1;
          $state.go('clinic.checkin');
        });
    }

    function getDraftInput() {
      const draft = angular.toJson(vm.ticket);

      return {
        employeeId: vm.employeeId,
        clinicLocationId: vm.clinic.clinicLocationId,
        ticketType: vm.ticket.ticketType,
        ticketDraft: draft,
      };
    }

    function saveDraft() {
      const input = getDraftInput();

      vm.savingDraft += 1;
      patientTicketSvc
        .saveTicketDraft(input)
        .success(() => {
          abp.notify.info(App.localize('DraftSaved'));
          vm.setPristine();
        })
        .finally(() => {
          vm.savingDraft -= 1;
        });
    }

    function validateLateSubmission(patientSessionId) {
      if (
        vm.patientSessionId !== patientSessionId ||
        _.isNil($stateParams.lateSubmissionRequestorName) ||
        _.isNil($stateParams.lateSubmissionReason)
      ) {
        abp.message.error(App.localize('MissingLateSubmissionInformation'));
        $state.go('clinic.panelDashboard');
      }
    }

    function updatePlaceholder($event, treatment) {
      const placeholder = treatment ? vm.getRemarkPlaceholder(treatment) : '';

      $($event.target).find('input').attr('placeholder', placeholder);
    }

    function previewTicket() {
      const input = getDraftInput();

      vm.savingDraft += 1;
      patientTicketSvc
        .saveTicketDraft(input)
        .success(() => {
          $state.go('clinic.previewTicket', {
            employeeId: $stateParams.employeeId,
            clinicLocationId: $stateParams.clinicLocationId,
            ticketType: $stateParams.ticketType,
            patientSessionId: $stateParams.patientSessionId,
            reason: $stateParams.reason,
            requestorName: $stateParams.requestorName,
          });
        })
        .finally(() => {
          vm.savingDraft -= 1;
          vm.saving -= 1;
        });
    }

    function confirm() {
      updateStepIndicator(vm.isConfirm);
      if (vm.isConfirm && vm.isDirty()) {
        vm.setPristine();
      }
    }

    function getEmployees(successCallback) {
      vm.loading += 1;
      if (vm.hasSearchEmployeesForPanelEnhancement) {
        clinicEmployeeSvc
          .getEmployeesForGP({
            employeeId: vm.employeeId,
            tenantId: vm.patient.corporate.id,
            clinicLocationId: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            processGetEmployeesOutput(data.items, successCallback);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        clinicEmployeeSvc
          .getPanelEmployees({
            employeeId: vm.employeeId,
            tenantId: vm.patient.corporate.id,
            clinicLocationId: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            processGetEmployeesOutput(data.items, successCallback);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function processGetEmployeesOutput(data, callback) {
      const employee = _.first(_.flatMap(data, (x) => x.employees));

      employee.allowanceDisplay = Math.min(employee.allowance, vm.defaultMaxAllowance);

      if (employee.coPay) {
        if (employee.coPay.isPercentage) {
          employee.coPay.title = App.localize('CopayX', `${employee.coPay.value}%`);
          employee.coPay.text = App.localize(
            'PatientHasCopayPercentage',
            `${employee.coPay.value}%`
          );
        } else {
          const valueText = $filter('currencyFormat')(employee.coPay.value, vm.currencyCode);
          employee.coPay.title = App.localize('CopayX', valueText);
          employee.coPay.text = App.localize('PatientHasCopayFixedAmount', valueText);
        }
      }

      const flattenUncoveredTreatments = _.uniqBy(
        _.flattenDeep(employee.relatedTreatments, (treatment) => treatment),
        'id'
      );

      const childTreatments = _.filter(
        flattenUncoveredTreatments,
        (treatment) => treatment.parentId !== null
      );

      _.each(childTreatments, (treatment) => {
        const parent = _.find(flattenUncoveredTreatments, (t) => t.id === treatment.parentId);

        if (typeof parent.children === 'undefined') parent.children = [];
        parent.children.push(treatment);
      });

      const roots = _.filter(
        flattenUncoveredTreatments,
        (treatment) => treatment.parentId === null
      );

      employee.processedUncoveredTreatments = roots;

      vm.employee = employee;
      if (_.isFunction(callback)) callback();
    }

    function checkMultipleCheckInWithSharedPool() {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkHasMultipleCheckInWithSharedPool({
          panelLocationId: vm.clinic.clinicLocationId,
          employeeId: vm.employeeId,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType,
        })
        .success((data) => {
          vm.hasMultipleCheckInWithSharedPool = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
    function setDirty() {
      if (vm.onConsultationDirtyEvent.handler) {
        vm.componentStates.consultation.isDirty = vm.onConsultationDirtyEvent.handler();
      }

      if (vm.onMedicalCertificateDirtyEvent.handler) {
        vm.componentStates.medicalCertificate.isDirty = vm.onMedicalCertificateDirtyEvent.handler();
      }

      if (vm.onTreatmentDirtyEvent.handler) {
        vm.componentStates.treatment.isDirty = vm.onTreatmentDirtyEvent.handler();
      }

      if (vm.onPharmacyDirtyEvent.handler) {
        vm.componentStates.pharmacy.isDirty = vm.onPharmacyDirtyEvent.handler();
      }
    }

    function updateStepIndicator(hasCompleted) {
      $scope.$broadcast(hasCompleted ? 'prescriptionTicket' : 'createTicket');
    }
  }
})();

(() => {
  angular.module('app').component('hostCorporatesCreateEditProperties', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditPropertiesController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {
      data: '<',
      accountOwners: '<',
      currencyCode: '=',
      countryCode: '=',
      paymentMode: '=',
    },
  });

  CorporateEditPropertiesController.$inject = [
    '$scope',
    'abp.services.app.corporate',
    'abp.services.app.commonLookup',
    'Hms.MultiTenancy.Tenant',
    'Abp.Configuration.Setting',
  ];

  function CorporateEditPropertiesController(
    $scope,
    corporateSvc,
    commonLookupSvc,
    constsTenant,
    constsAbpConfig
  ) {
    const vm = this;
    vm.constsTenant = constsTenant;
    vm.constsAbpConfig = constsAbpConfig;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = false;
    vm.isEditing = false;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.creationInput = {
      admin: {
        autoGeneratePassword: true,
        shouldChangePasswordOnNextLogin: true,
      },
    };

    vm.permissions = {
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
      override: abp.auth.isGranted('Host.Corporates.Override'),
    };

    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.selectedAccountOwnerDetails = selectedAccountOwnerDetails;
    vm.regenerateCorporateCode = regenerateCorporateCode;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = save;
    vm.$onChanges = changes;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
      getIndustries();
      getTimeZones();
    }

    function isDirty() {
      return (
        $scope.corporateCreateEditPropertiesForm && $scope.corporateCreateEditPropertiesForm.$dirty
      );
    }

    function setPristine() {
      $scope.corporateCreateEditPropertiesForm.$setPristine();
    }

    function getIndustries() {
      vm.loading += 1;
      commonLookupSvc
        .getIndustries()
        .success((data) => {
          vm.industries = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTimeZones() {
      vm.loading += 1;
      commonLookupSvc
        .getTimeZones()
        .success((data) => {
          vm.timeZones = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function selectedAccountOwnerDetails(accountOwnerId) {
      const accountOwner = _.find(vm.accountOwners, ['id', accountOwnerId]);
      vm.currencyCode = accountOwner.currencyCode;
      vm.countryCode = accountOwner.countryCode;
      vm.paymentMode = accountOwner.paymentAccountNumberMode;

      vm.data.timeZoneId = accountOwner.timeZoneId;
    }

    function regenerateCorporateCode() {
      vm.loading += 1;
      corporateSvc
        .generateCorporateCode()
        .success((data) => {
          vm.data.tenancyName = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Force validation on this form.
    // True if valid, otherwise, false.

    function validateForm(error) {
      const form = $scope.corporateCreateEditPropertiesForm;
      App.touchFormErrors(form);
      error = !form.$valid;
    }

    // Generate the payload from this form to send to CorporateAppService API.

    function getPayload(settings) {
      let payload = {
        properties: vm.data,
      };

      payload.properties.startDate = App.getDateString(vm.data.momentStartDate);
      payload.properties.commencementDate = App.getDateString(vm.data.momentCommencementDate);

      if (vm.isCreate) {
        payload = _.extend({}, vm.creationInput, payload);
      }
      
      _.extend(settings, payload);
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function changes() {
      vm.isCreate = vm.data ? !vm.data.id : false;

      if (vm.isCreate) vm.regenerateCorporateCode();
    }
  }
})();

(() => {
  angular.module('app').component('clinicPatientTicketConsultation', {
    templateUrl: require('./widget.html'),
    controller: ClinicTicketConsultationController,
    controllerAs: 'vm',
    bindings: {
      isDisabled: '<',
      isExpanded: '<',
      isCompleted: '<',
      onDirtyEvent: '<',
      onSetPristineEvent: '<',
      isCreate: '<',
      isCorporate: '<',
      patient: '<',
      consultationAveragePrice: '<',
      doctors: '<',
      ticket: '<',
      icpcs: '<',
      onCreateDoctor: '&',
      onSyncExpansion: '&',
      onComplete: '&',
      currencyCode: '<',
      countryCode: '<',
    },
  });

  ClinicTicketConsultationController.$inject = ['$filter'];

  function ClinicTicketConsultationController($filter) {
    const vm = this;

    vm.doctor = {};
    vm.isOpen = false;
    vm.isOpenPreviousValue = false;
    vm.isPermanent = 0;
    vm.isCreate = !vm.ticket.id;

    const hasConsultation = !_.isNil(vm.ticket.hasConsultation) ? vm.ticket.hasConsultation : null;

    vm.ticket.hasConsultation = vm.isCreate ? hasConsultation : !!vm.ticket.consultation;

    vm.$onChanges = onChanges;
    vm.$doCheck = doCheck;
    vm.onDirtyEventHandler = onDirtyEventHandler;
    vm.onSetPristineEventHandler = onSetPristineEventHandler;
    vm.next = next;
    vm.updateDoctor = updateDoctor;

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue && vm.ticket.doctorId) {
        vm.updateDoctor();
      }

      if (changes.isExpanded) {
        vm.isOpen = vm.isExpanded;
        updatePostHeader();
      }

      if (changes.onDirtyEvent && changes.onDirtyEvent.currentValue) {
        vm.onDirtyEvent.handler = vm.onDirtyEventHandler;
      }

      if (changes.onSetPristineEvent && changes.onSetPristineEvent.currentValue) {
        vm.onSetPristineEvent.handler = vm.onSetPristineEventHandler;
      }
    }

    function doCheck() {
      if (vm.isOpen !== vm.isOpenPreviousValue) {
        // Component expansion MUST be in sync with parent component's isExpanded
        // state to work as intended.
        // uib-accordion toggles isOpen by itself when user clicks on it.

        vm.onSyncExpansion({ isExpanded: vm.isOpen });
        vm.isOpenPreviousValue = vm.isOpen;
      }
    }

    function onDirtyEventHandler() {
      return vm.consultationForm.$dirty;
    }

    function onSetPristineEventHandler() {
      vm.consultationForm.$setPristine();
    }

    function areFieldsValid() {
      if (_.isNil(vm.ticket.hasConsultation)) {
        vm.showSelectOptionMessage = _.isNil(vm.ticket.hasConsultation);
        return false;
      }
      return true;
    }

    function next() {
      if (App.isFormValid(vm.consultationForm) && areFieldsValid()) {
        vm.onComplete();
      }
    }

    function updateDoctor() {
      vm.doctor = _.find(vm.doctors, (d) => d.id === vm.ticket.doctorId);
    }

    /* Functions */

    function updatePostHeader() {
      const message = [];

      const doctorInformation = vm.doctor.mmcRegistrationNumber
        ? `${vm.doctor.name} ${$filter('doctorMmcRegistrationNumber')(vm.doctor, vm.countryCode)}`
        : vm.doctor.name;

      if (vm.ticket.consultation && vm.ticket.consultation.symptoms) {
        message.push(
          App.localize(
            'ConsultationNarrative',
            doctorInformation,
            vm.ticket.consultation.symptoms.join(', ')
          )
        );
        message.push(vm.currencyCode + vm.ticket.consultation.amount);
      } else {
        message.push(App.localize('NoConsultationNarrative', doctorInformation));
      }

      if (vm.ticket && vm.ticket.referralLetter) {
        message.push(App.localize('ReferralLetterNarrative'));
        message.push(vm.currencyCode + vm.ticket.referralLetter.amount);
      }

      vm.summaryMessage = message.join('. ');
    }

    /* End of Functions */
  }
})();

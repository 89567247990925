(() => {
  angular.module('app').component('hostClinicsPanelPropertiesEditorComponent', {
    templateUrl: require('./panelPropertiesEditor.component.html'),
    controller: PanelPropertiesEditorController,
    controllerAs: 'vm',
    bindings: {
      data: '<',
      panelRequest: '<', // For prefilling
    },
    require: {
     container: '^hostClinicsPanelEditorContainerComponent',
    },
  });

  PanelPropertiesEditorController.$inject = [
    '$scope',
    'abp.services.app.commonLookup',
    'Hms.MultiTenancy.TenantClinicType',
    'Tenant',
    'Hms.Users.User',
  ]

  function PanelPropertiesEditorController(
    $scope,
    commonLookupSvc,
    enumTenantClinicType,
    constsTenant,
    constsUser
  ) {
    const vm = this;
    vm.constsTenant = constsTenant;
    vm.constsUser = constsUser;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isEditing = false;

    // Check for inpatient module enable for the application.

    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;

    vm.permissions = {
      changeTreatmentRates: abp.auth.isGranted('Host.Clinics.ChangeTreatmentRates')
    };

    vm.$onInit = function () {
      vm.container.addWidget(this);

      // Get account owners.

      if (vm.hasRegionalSystemSupport) {
        vm.loading += 1;
        commonLookupSvc.getAccountOwners().success((data) => {
          vm.accountOwners = data;
        }).finally(() => {
          vm.loading -= 1;
        });
      }
      getTimeZones();
    };

    vm.$onChanges = function (changes) {
      if (vm.data) {
        vm.isCreate = vm.data.properties ? !vm.data.properties.id : false;
      }

      if (changes.panelRequest) prefill(vm.panelRequest);

      if (vm.data && (vm.data.properties.bankGuaranteeAmount 
        || vm.data.properties.bankGuaranteeRenewDate)) {
        vm.hasBankGuarantee = true;
      }
    };

    vm.isDirty = function () {
      return $scope.panelCreateEditPropertiesForm 
        && $scope.panelCreateEditPropertiesForm.$dirty;
    };

    vm.setPristine = function () {
      $scope.panelCreateEditPropertiesForm.$setPristine();
    };

    function prefill(request) {
      if (!request || !vm.data.properties || !vm.isCreate) return;

      vm.data.properties.name = request.panelName;
      vm.data.properties.clinicType = request.panelType;
      vm.data.properties.contactNumber = request.contactNumber;
      vm.creationInput.admin.emailAddress = request.email;
      vm.creationInput.panelRequestId = request.id;
      vm.data.properties.accountOwnerCountryCode = request.countryCode;

      // Get filtered account owners.

      if (vm.hasRegionalSystemSupport) {
        vm.loading += 1;
        commonLookupSvc.getAccountOwners().success((data) => {
          vm.filteredAccountOwners = _.filter(data,
            x => x.countryCode === request.countryCode);
        }).finally(() => {
          vm.loading -= 1;
        });
      }
    }

    // The payload for creation-purpose only

    vm.creationInput = {
      admin: {
        autoGeneratePassword: true,
        shouldChangePasswordOnNextLogin: true
      }
    };

    // Force validation on this form.
    // True if valid, otherwise, false.

    vm.validateForm = function (error) {
      const form = $scope.panelCreateEditPropertiesForm;
      App.touchFormErrors(form);
      error = !form.$valid;
    };

    // Generate the payload from this form to send to ClinicAppService API.

    vm.getPayload = function (settings) {
      let payload = {
        properties: vm.data.properties
      };

      payload.properties.startDate = App.getDateString(vm.data.momentStartDate)

      if (!vm.hasBankGuarantee) {
        vm.data.properties.bankGuaranteeRenewDate = null;
        vm.data.properties.bankGuaranteeAmount = null;
      }

      if (vm.isCreate) {
        payload = _.extend({}, vm.creationInput, payload);
      }
      
      _.extend(settings, payload);
    };

    // Ask container to save this widget.

    vm.save = function () {
      vm.container.saveWidget(vm);
    };

    // Toggle bank guarantee.

    vm.toggleBankGuarantee = function () {
      vm.hasBankGuarantee = !vm.hasBankGuarantee;
    };

    // Change account owner country code.

    vm.changeAccountOwnerCountryCode = function () {
      const accountOwnerList = vm.panelRequest ? vm.filteredAccountOwners : vm.accountOwners;
      const selectedAccountOwner = _.find(accountOwnerList, (accountOwner) =>
        accountOwner.id === vm.data.properties.accountOwnerId);

      if (selectedAccountOwner) {
        vm.data.properties.accountOwnerCountryCode = selectedAccountOwner.countryCode;
        vm.data.properties.timeZoneId = selectedAccountOwner.timeZoneId;
      }
    };

    function getTimeZones() {
     vm.loading += 1;
     commonLookupSvc.getTimeZones().success((data) => {
       vm.timeZones = data;
     }).finally(() => {
       vm.loading -= 1;
     });
    }
  }

})();
